import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from '../../../store/reducers';
import {authTokenSelector, CustomCard, Form, FormControlChangeType, IFormConfig, Translation} from 'meditrip-common-web';
import {withTranslation, WithTranslation} from 'react-i18next';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {Subscription} from "rxjs";
import {calendarFormConfig} from "./calendarFormConfig";
import moment from "moment";
import styles from "./styles.module.scss";
import {
    retrievedTreatmentPlansItemSelector,
    treatmentPlansItemErrorSelector,
    treatmentPlansItemEventSourceSelector,
    treatmentPlansItemLoadingSelector
} from "../../../store/selectors/treatmentPlansItemSelectors";
import {reset, retrieve} from '../../../actions/treatmentPlans/show';
import {EventType} from "../index";

interface IConnectedTreatmentPlannerViewProps {
    readonly authToken: string;
    readonly retrieved?: any;
    readonly retrieve: typeof retrieve;
    readonly reset: any;
    readonly eventSource: EventSource;
    readonly loading: boolean;
    readonly error: string;
}

interface IExternalTreatmentPlannerViewProps {}

interface ITreatmentPlannerViewProps extends IConnectedTreatmentPlannerViewProps,
    IExternalTreatmentPlannerViewProps,
    RouteComponentProps,
    WithTranslation {}

interface ITreatmentPlannerViewState {
    formConfig: typeof IFormConfig;
    value: any;
    selectedTreatmentDate: Date | null;
    treatmentPlan: {[key: string]: any} | null;
    recommendationsTravelPlanDates: any[] | null;
    recommendationsAftercareDates: any[] | null;
}

class TreatmentPlannerView extends React.Component<ITreatmentPlannerViewProps, ITreatmentPlannerViewState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    private subscription: Subscription | null = null;

    constructor(props: ITreatmentPlannerViewProps) {
        super(props);

        this.state = {
            formConfig: calendarFormConfig,
            value: null,
            selectedTreatmentDate: null,
            treatmentPlan: null,
            recommendationsTravelPlanDates: null,
            recommendationsAftercareDates: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        const slug = this.props.match.params;
        this.props.retrieve(`treatment_plans/${(slug as any).id}`, this.props.authToken);
    }

    componentDidUpdate(
        prevProps: Readonly<ITreatmentPlannerViewProps>,
        prevState: Readonly<{}>,
        snapshot?: any
    ): void {
        if (this.props.retrieved !== prevProps.retrieved &&
            this.props.retrieved) {
            let config = calendarFormConfig;
            config.controls[0].controls.date.recommendationsTravelPlanDates = this.getRecommendationOccurrence(EventType.TRAVEL_PLAN);
            config.controls[0].controls.date.recommendationsAftercareDates = this.getRecommendationOccurrence(EventType.AFTERCARE);
            this.setState({
                treatmentPlan: this.props.retrieved,
                recommendationsTravelPlanDates: this.getRecommendationOccurrence(EventType.TRAVEL_PLAN),
                recommendationsAftercareDates: this.getRecommendationOccurrence(EventType.AFTERCARE),
                formConfig: config,
            });
        }

        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }
    }

    componentWillUnmount() {
        if (this.props.eventSource) {
            this.props.reset(this.props.eventSource);
        }

        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={`treatmentPlanner.title`}/>
                            </div>

                            <div>
                                <Link to={`/dashboard/treatmentPlans?page=1&itemsPerPage=10`} className="btn btn-no-outline">
                                    <span className="feather icon-chevron-left"/>
                                    <Translation text={'button.back'}/>
                                </Link>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-3 col-12">
                                <CustomCard showLocalLoader={this.props.loading} type={'travel-planner'}>
                                    <CustomCard.Header>
                                        {this.state.selectedTreatmentDate ?
                                            moment(this.state.selectedTreatmentDate).format('YYYY-MM-DD') : null}
                                    </CustomCard.Header>
                                    <CustomCard.Body>
                                        {this.renderTreatmentPointsList()}
                                    </CustomCard.Body>
                                </CustomCard>
                            </div>
                            <div className="col-xl-7 col-12">
                                <CustomCard showLocalLoader={this.props.loading}>
                                    <CustomCard.Body>
                                        <div className="d-flex align-middle event-form-container">
                                            <Form config={this.state.formConfig}
                                                  controlName={'calendarForm'}
                                                  onValueStateChange={this.onValueStateChange}
                                                  value={this.state.value}/>
                                        </div>
                                    </CustomCard.Body>
                                </CustomCard>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        if (controlName === 'calendarForm' && changeType === 'user') {
            this.setState({
                selectedTreatmentDate: value.date.value
            })
        }
    };

    private getRecommendationOccurrence(treatmentPointType: EventType) {
        if (!this.props.retrieved || !this.props.retrieved.treatmentPoints) {
            return null;
        }
        const map = this.props.retrieved.treatmentPoints
            .filter((item: {[key: string]: any}) => item.type === treatmentPointType)
            .reduce((acc: any, e: any) => acc.set(
                e.treatmentPointStartsAt.split('T')[0].replace(/-0/g, '-'),
                (acc.get(e.treatmentPointStartsAt.split('T')[0].replace(/-0/g, '-')) || 0) + 1
            ), new Map());

        return [...map.entries()];
    };

    private renderTreatmentPointsList = () => {
        if (((!this.props.retrieved ||
            !this.props.retrieved['treatmentPoints'] ||
            !Array.isArray(this.props.retrieved['treatmentPoints']) ||
            !this.props.retrieved['treatmentPoints'].length) &&
            !this.state.selectedTreatmentDate) ||
            !this.state.selectedTreatmentDate) {
            return (<p><Translation text={'treatmentPlanner.selectDate'}/></p>);
        }

        let treatmentPoints = this.getSelectedRecommendations(
            this.state.selectedTreatmentDate,
            this.props.retrieved['treatmentPoints']
        );

        if (!treatmentPoints || !treatmentPoints.length) {
            return (<p><Translation text={'treatmentPlanner.noTreatmentPointsAvailable'}/></p>);
        }

        return (
            <React.Fragment>
                {treatmentPoints.map((item: any) => {
                    return (
                        <div key={item.id}>
                            <div className={`status-wrapper ${item.type === EventType.AFTERCARE ? 'aftercare' : 'travel-plan'}`}>
                                <p className="status">
                                    <Translation
                                        text={`treatmentPlanner.eventType.${item.type === EventType.AFTERCARE ? 'aftercare' : 'travelPlan'}`}/>
                                </p>
                            </div>
                            <div className={styles.treatmentDetails}>
                                <p className={styles.treatmentTitle}>{item.name}</p>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    )
                })}
            </React.Fragment>
        )
    };

    private getSelectedRecommendations(selectedDate: Date, treatmentPointsList: {[key: string]: any}[]) {
        let recommendationDate = moment(selectedDate).format('YYYY-MM-DD');

        let treatmentPoints;
        treatmentPoints = treatmentPointsList.filter((treatmentPoint => {
            let date = moment(treatmentPoint.treatmentPointStartsAt).utcOffset(0).format('YYYY-MM-DD');

            return date === recommendationDate;
        }));

        return treatmentPoints;
    };

    private sortMethod(a: any, b: any): number {
        const aDate = new Date(a.startsAt),
            bDate = new Date(b.startsAt),
            aTime = aDate.getTime(),
            bTime = bDate.getTime();

        return aTime >= bTime ? -1 : 1;
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedTreatmentPlansItemSelector(state),
        authToken: authTokenSelector(state),
        eventSource: treatmentPlansItemEventSourceSelector(state),
        error: treatmentPlansItemErrorSelector(state),
        loading: treatmentPlansItemLoadingSelector(state),
    }),
    {
        retrieve,
        reset
    }
)(withRouter(TreatmentPlannerView)));
