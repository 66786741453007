const en = {
    chat: {
        title: "Chat with Clinics",
        noNodeConnection: "Chat server is unavailable. Please check again later",
        fileSend: "Send file",
        noMessages: "There are no messages yet.",
        fileSize: "File size",
        fileIsReadyToSent: 'File is ready to be sent!',
        inputPlaceholder: 'Type something...',
        connectionProblems: 'Something went wrong. Chat is currently unavailable. Check your internet connection or try to refresh page.',
        noUserListProvided: 'No users list was provided',
        online: 'online',
        connectionFailed: 'Unable to establish direct connection',
        connecting: 'Connecting to chat...',
        userNamePlaceholder: 'Unknown username',
        noConnectionReasons: 'Possible reasons are listed bellow: ',
        not_authorized: 'Wrong token provided. Try login again',
        'websocket error': 'Some other reasons are possible',
        already_online: `Some other user logged onto this account. If it's you, please logout on other devices`,
        no_token: 'No token provided. Try login again',
        expired_token: 'Expired token provided. Try login again',
    },
    formValidation: {
        errors: {
            minLength: "The input is too short",
            isEmailValid: "Provide a valid email address",
            isRequired: "The field is required",
            isTrue: "Must be checked",
            isPasswordSame: "Passwords not match",
            isStartDateValid: "Date must be after current day",
            isStartDateGreaterOrEvenValid: "Date must be at or after current day",
            isEndDateValid: "Date of return must be after departure"
        }
    },
    setting: {
        title: "Settings",
        addressData: {
            title: "Address Information"
        },
        changePassword: {
            title: "Change Password"
        },
        treatmentCategories: {
            title: "Treatment Categories"
        },
        invoiceData: {
            title: "Invoice Data"
        },
        personalData: {
            title: "Personal Data"
        },
        clinicLogo: {
            title: "Clinic Logo",
            placeholder: "Click or Drag files to this area to upload"
        }
    },
    profile: {
        title: "Profile",
        clinicDetails: {
            title: "Clinic Details"
        },
        clinicPhotos: {
            title: "Clinic Photos"
        },
        doctors: {
            title: "Doctors"
        },
        generalInformation: {
            title: "General Information"
        },
        treatmentCategories: {
            title: "Treatment Categories"
        }
    },
    consultations: {
        consultationsList: {
            title: 'My Consultations',
            newConsultation: 'New consultation',
            noData: 'There is no data available',
            table: {
                date: 'Date',
                clinicName: 'Clinic name',
                consultationName: 'Consultation name',
                status: 'Status',
                actions: 'Actions',
                searchPlaceholder: 'Enter consultation name',
                datePickerPlaceholder: 'Select Date Range',
                rescheduleConsultation: 'Reschedule',
                cancelConsultation: 'Cancel Consultation',
                openConsultation: 'Open',
                cancelModalTitle: 'Are you sure you want to cancel your consultation?'
            },
            statuses: {
                scheduled: 'Scheduled',
                draft: 'Draft',
                cancelled: 'Canceled',
                succeeded: 'Succeeded',
                notStarted: 'Not Started',
                started: 'Started'
            }
        }
    },
    treatmentPlanner: {
        title: 'Treatment Planner',
        table: {
            treatmentType: 'Treatment Type',
            status: 'Status'
        },
        calendarTitle: 'Treatment Calendar',
        noDataAvailable: 'There is no data available',
        noTreatmentPointsAvailable: 'There is no treatment available for selected date',
        selectDate: 'Pick a date to see Events',
        statuses: {
            active: "Active",
            draft: "Draft",
            inactive: "Inactive",
            invited: "Invited",
            expired: "Expired"
        },
        eventType: {
            aftercare: 'Aftercare',
            travelPlan: 'Travel plan'
        }
    },
    modal: {
        confirm: "Confirm"
    },
    button: {
        yes: 'Yes',
        no: 'No',
        back: 'Back'
    }

};

export default en;
