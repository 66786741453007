import {FormButtonType, FormControlType, InputType, ValidationRules} from 'meditrip-common-web';

export const addressDataFormConfig: { [key: string]: any } = {
    class: 'col-xl-12',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'phone',
            class: 'row',
            controls: {
                phone: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 9}},
                        // {name: ValidationRules.IS_NUMBER},
                    ],
                    placeholder: 'Phone',
                    label: 'Phone',
                    type: InputType.TEL,
                    hostClass: 'col-xl-12',
                },
                // email: {
                //   controlType: 'control',
                //   defaultValue: '',
                //   formControlType: FormControlType.INPUT,
                //   validationRules: [
                //     {name: ValidationRules.IS_REQUIRED},
                //     {name: ValidationRules.IS_EMAIL},
                //   ],
                //   placeholder: 'Email',
                //   label: "Email",
                //   type: InputType.EMAIL,
                //   hostClass: 'col-xl-6',
                // }
            }
        },
        {
            controlType: 'group',
            key: 'address_line1',
            class: 'row',
            controls: {
                addressLine1: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Street, number',
                    label: 'Street',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                },
                // addressLine2: {
                //   controlType: 'control',
                //   defaultValue: '',
                //   formControlType: FormControlType.INPUT,
                //   validationRules: [
                //     {name: ValidationRules.IS_REQUIRED},
                //   ],
                //   placeholder: 'House',
                //   label: "House",
                //   type: InputType.TEXT,
                //   hostClass: 'col-3',
                // },
                // addressLine3: {
                //   controlType: 'control',
                //   defaultValue: '',
                //   formControlType: FormControlType.INPUT,
                //   placeholder: 'Flat',
                //   label: "Flat",
                //   type: InputType.TEXT,
                //   hostClass: 'col-3',
                // }
            }
        },
        {
            controlType: 'group',
            key: 'address_2',
            class: 'row',
            controls: {
                city: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'City',
                    label: 'City',
                    touched: false,
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6',
                },
                // country: {
                //   controlType: 'control',
                //   defaultValue: '',
                //   formControlType: FormControlType.AUTOCOMPLETE,
                //   multiselectType: MultiSelectType.SINGLE,
                //   validationRules: [
                //     { name: ValidationRules.IS_REQUIRED },
                //   ],
                //   multiselectOptions: fullCountryList,
                //   placeholder: 'Country',
                //   label: "Country",
                //   hostClass: 'col-xl-3',
                //   inputDataMapper: (data: any) => {
                //     if (!data) {
                //       return [];
                //     }
                //
                //     return fullCountryList
                //       .filter((country: any) => country.label === data)
                //       .map((country: any) => ({
                //         value: country.value,
                //         label: country.label,
                //       }));
                //   },
                //   outputDataMapper: (data: any) => {
                //     if (Array.isArray(data)) {
                //       return (data.length > 0) ? data[0].value : null;
                //     }
                //     if (data && typeof data === 'object') {
                //       return data.value;
                //     }
                //
                //     return data;
                //   },
                // },
                postcode: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Postcode',
                    label: 'Postcode',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6',
                }
            }
        },
        {
            controlType: 'group',
            key: 'address_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-3 pr-0',
                    defaultValue: null,
                    btnText: 'Save',
                    defaultStyles: 'btn btn-primary'
                },
            },
        },
    ]
};
