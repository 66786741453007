import {combineEpics} from 'redux-observable';
import {
    accountEpic,
    changePasswordEpic,
    clinicEpic,
    loginEpic,
    registrationEpic,
    renewAuthTokenEpic,
    resetPasswordEpic,
    sharedLoginEpic,
    reauthenticateEpic
} from 'meditrip-common-web';

export const rootEpic = combineEpics(
    loginEpic,
    registrationEpic,
    resetPasswordEpic,
    renewAuthTokenEpic,
    accountEpic,
    changePasswordEpic,
    clinicEpic,
    sharedLoginEpic,
    reauthenticateEpic
);
