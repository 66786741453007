import React from "react";
import {RootState} from "../../../store/reducers";
import {connect} from "react-redux";
import {
  accountLoadingSelector,
  accountSelector,
  CustomCard,
  Form,
  Translation,
  updateAccountState
} from 'meditrip-common-web';
import {addressDataFormConfig} from "./addressDataFormConfig";

interface IAddressDataConnectedProps {
  readonly updateAccountState: typeof updateAccountState;
  readonly isLoading: boolean;
  readonly account: any;
}

interface IAddressDataExternalProps {}

interface IAddressDataProps extends IAddressDataConnectedProps,
  IAddressDataExternalProps {
}

interface IAddressDataState {
  formConfig: any;
  isLoading: boolean;
  value: any;
}

class AddressData extends React.Component<IAddressDataProps, IAddressDataState> {
  constructor(props: IAddressDataProps) {
    super(props);

    this.state = {
      formConfig: addressDataFormConfig,
      isLoading: false,
      value: null
    };
  }

  componentDidMount() {
    if (this.props.account) {
      this.updateFormFromState();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IAddressDataProps>,
    prevState: Readonly<IAddressDataState>,
    snapshot?: any
  ): void {
    if (this.props.isLoading !== prevProps.isLoading
      && !this.props.isLoading) {
      this.setState({isLoading: this.props.isLoading})
    }

    if (this.props.account !== prevProps.account) {
      this.updateFormFromState();
    }
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.state.isLoading}>
        <CustomCard.Header>
          <Translation text={'setting.addressData.title'}/>
        </CustomCard.Header>
        <CustomCard.Body>
          <Form config={this.state.formConfig}
                submitForm={this.changeAddressData}
                value={this.state.value}
                controlName={'addressDataForm'}/>

        </CustomCard.Body>
      </CustomCard>
    );
  }

  private changeAddressData = (event: any, value: any, valid: boolean, touched: boolean) => {
    if (!valid || !touched) {
      return;
    }

    this.setState({isLoading: true});
    const account = this.props.account,
      address = account.address;

    const payload = {
      id: account.id,
      firstName: account.firstName,
      lastName: account.lastName,
      birthDate: account.birthDate,
      address: {
        addressLine1: value.addressLine1 ? value.addressLine1 : (address ? address.addressLine1 : null),
        addressLine2: value.addressLine2 ? value.addressLine2 : (address ? address.addressLine2 : null),
        addressLine3: value.addressLine3 ? value.addressLine3 : (address ? address.addressLine3 : null),
        postcode: value.postcode ? value.postcode : (address ? address.postcode : null),
        city: value.city ? value.city : (address ? address.city : null),
        country: value.country ? value.country : (address ? address.country : null)
      },
      invoiceAddress: account.invoiceAddress,
      phone: value.phone,
      residency: account.residency
    };

    this.props.updateAccountState(payload);
  };

  private mapAccountToFormData(account: any): any {
    return {
       phone: account.phone,
       addressLine1: account.address?.addressLine1,
       city: account.address?.city,
       country: account.address?.country,
       postcode: account.address?.postcode
    };
  }

  updateFormFromState() {
    this.setState({
      value: this.mapAccountToFormData(this.props.account)
    })
  }
}

export default connect(
  (state: RootState) => ({
    account: accountSelector(state),
    isLoading: accountLoadingSelector(state)
  }),
  {
    updateAccountState
  }
)(AddressData);
