import { combineReducers } from "redux";
import saga from "./sagaSlice";
import {connectRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import {
  alertSlice,
  authSlice,
  loginSlice,
  registrationSlice,
  resetPasswordSlice,
  changePasswordSlice,
  accountSlice,
  patientSlice,
  clinicSlice
} from "meditrip-common-web";
import inquiry from "../../reducers/inquiry";
import consultation from "../../reducers/consultation";
import treatmentPlans from "../../reducers/treatmentPlans";

const history = createBrowserHistory();

const rootReducer = combineReducers({
    saga: saga,
    account: accountSlice,
    auth: authSlice,
    login: loginSlice,
    registration: registrationSlice,
    resetPassword: resetPasswordSlice,
    changePassword: changePasswordSlice,
    alert: alertSlice,
    inquiry: inquiry,
    consultation: consultation,
    patient: patientSlice,
    clinic: clinicSlice,
    treatmentPlans: treatmentPlans,
    router: connectRouter(history)
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
