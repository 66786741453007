import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {list, reset} from '../../actions/consultation/list';
import {RootState} from '../../store/reducers';
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    CustomPagination,
    formatDateToString,
    RestQueryParams,
    Translation
} from 'meditrip-common-web';
import {DateRangePicker} from 'rsuite';
import {isNullOrUndefined} from '../../utils/runtimeUtils';
import {withTranslation, WithTranslation} from 'react-i18next';
import moment from 'moment';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {
    retrievedConsultationsListSelector,
    consultationListLoadingSelector,
    consultationListErrorSelector,
    consultationListEventSourceSelector
} from "../../store/selectors/consultationListSelector";
import {Subscription, of} from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {cancelConsultationAPI} from "../../api/cancelConsultation";

export enum ConsultationStatus {
    SCHEDULED = 'scheduled',
    DRAFT = 'draft',
    CANCELLED = 'cancelled',
    SUCCEEDED = 'succeeded',
    FINISHED = 'finished',
    STARTED = 'started',
    NOT_STARTED = 'not-started'
}

const datepickerRanges: any[] = [
    {
        label: 'Previous Month',
        value: [
            new Date(moment().subtract(1,'months').startOf('month').format()),
            new Date(moment().subtract(1,'months').endOf('month').format())
        ]
    },
    {
        label: 'last7Days',
        value: [
            new Date(moment().subtract(7, 'days').format()),
            new Date(moment().endOf('day').format())]
    },
    {
        label: 'today',
        value: [
            new Date(moment().startOf('day').format()),
            new Date(moment().endOf('day').format())
        ]
    },
    {
        label: 'Current Month',
        value: [
            new Date(moment().startOf('month').format()),
            new Date(moment().endOf('month').format())
        ]
    },
];

interface IConnectedConsultationProps {
    readonly retrieved: any;
    readonly loading: boolean;
    readonly error: string;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly authToken: string;
}

interface IExternalConsultationProps {
}

interface IConsultationProps extends IConnectedConsultationProps,
    IExternalConsultationProps,
    RouteComponentProps,
    WithTranslation {}

interface IConsultationState {
    startDate: Date | null;
    deleteModalShown: boolean;
    selectedConsultationId: string | null;
}

class Consultations extends React.Component<IConsultationProps, IConsultationState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    private subscription: Subscription | null = null;

    constructor(props: IConsultationProps) {
        super(props);

        this.state = {
            startDate: null,
            deleteModalShown: false,
            selectedConsultationId: null
        };

        fixInjectedProperties(this);
    }

    componentDidUpdate(
        prevProps: Readonly<IConsultationProps>,
        prevState: Readonly<{}>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);


        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        const {t} = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={`consultations.consultationsList.title`}/>
                            </div>
                            {/*<div className="action-container">*/}
                            {/*    <a href={`${process.env.REACT_APP_ONLINE_CONSULTATION_URL}`} className="btn btn-theme">*/}
                            {/*        <Translation text={`consultations.consultationsList.newConsultation`}/>*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                        </div>
                        <div className="filter-container">
                            <div className="group-control">
                                <DateRangePicker placeholder={t(`consultations.consultationsList.table.datePickerPlaceholder`)}
                                                 onChange={this.handleDateChange}
                                                 ranges={datepickerRanges}
                                                 // placement="autoVertical"
                                                 renderValue={(value) => {
                                                     return `${formatDateToString(value[0])} - ${formatDateToString(value[1])}`;
                                                 }}
                                />
                            </div>
                        </div>

                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Body>
                                { this.renderConsultationsList() }
                                    <CustomPagination retrieved={this.props.retrieved}
                                                      basePath="dashboard"
                                                      path="consultations"
                                                      provider={this.getConsultationsList}
                                    />
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>

                <BasicModal isModalShown={this.state.deleteModalShown} closeModal={this.closeDeleteModal}>
                    <CustomCard type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={"consultations.consultationsList.table.cancelConsultation"}/>
                                <button className="btn-modal-close" onClick={() => this.toggleDeleteModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Translation text={'consultations.consultationsList.table.cancelModalTitle'}/>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-danger-outline mr-4"
                                        onClick={() => this.toggleDeleteModal()}>
                                    <Translation text={'button.no'}/>
                                </button>
                                <button className="btn btn-secondary-theme"
                                        onClick={() => this.cancelConsultation()}>
                                    <Translation text={'button.yes'}/>
                                </button>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private renderConsultationsList = () => {
        const retrieved = this.props.retrieved;
        if (!retrieved || !retrieved['hydra:member'] || !Array.isArray(retrieved['hydra:member'])) {
            return (<p>There is no data available</p>);
        }

        const list = this.props.retrieved['hydra:member'].sort(this.sortMethod);

        return (
            <table className="data-table">

                { this.renderTableHeader() }

                { this.renderTableBody(list) }

            </table>
        )
    };

    private renderTableHeader() {
        return (
            <thead>
            <tr>
                <th><Translation text={`consultations.consultationsList.table.date`}/></th>
                <th><Translation text={`consultations.consultationsList.table.clinicName`}/></th>
                <th><Translation text={`consultations.consultationsList.table.consultationName`}/></th>
                <th className="text-center"><Translation text={`consultations.consultationsList.table.status`}/></th>
                <th />
            </tr>
            </thead>
        )
    }

    private renderTableBody(list: any = null) {
        const rows: any[] = [];
        list.map((item: any) => {
            const hasConsultationPassed = this.isConsultationValid(item.startsAt),
                consultationStatus = item.status === ConsultationStatus.FINISHED ? ConsultationStatus.SUCCEEDED : item.status,
                translationKey = consultationStatus.toLowerCase().replace(/_([a-z0-9])/g, (g: string) => g[1].toUpperCase());

            return rows.push((
                <tr key={item['@id']}>
                    <td className="align-middle">{this.renderConsultationTime(item.startsAt, item.endsAt)}</td>
                    <td className="align-middle">{item.clinic.companyName}</td>
                    <td className="align-middle">{item.calendar ? item.calendar.name : '-'}</td>
                    <td className="text-center align-middle">
                        <div className={`status-wrapper consultation-${consultationStatus.replace('_', '-')}`}>
                            <p className="status">
                                <Translation text={`consultations.consultationsList.statuses.${translationKey}`}/>
                            </p>
                        </div>
                    </td>
                    <td className="align-middle text-right">
                        {!hasConsultationPassed && item.status !== ConsultationStatus.CANCELLED ?
                            (<button className="btn btn-action mr-2"
                                     onClick={() => this.openConsultation(item)}>
                                <span className="feather icon-check-square"/>
                            </button>) : null}

                        {/*<button className={`${"btn btn-action mr-2"}*/}
                        {/*                    ${hasConsultationPassed ? "disabled" : ''}`}*/}
                        {/*        disabled={hasConsultationPassed}*/}
                        {/*        onClick={() => this.goToConsultationForm(item['id'])}>*/}
                        {/*    <span className="feather icon-edit"/>*/}
                        {/*</button>*/}

                        {/*<button className={`${"btn btn-action"}*/}
                        {/*                    ${hasConsultationPassed ? "disabled" : ''}`}*/}
                        {/*        disabled={hasConsultationPassed}*/}
                        {/*        onClick={() => this.toggleDeleteModal(item['id'])}>*/}
                        {/*    <span className="feather icon-trash"/>*/}
                        {/*</button>*/}
                    </td>
                </tr>
            ))
        });

        return (<tbody>{ rows }</tbody>
        );
    }

    private toggleDeleteModal = (itemId?: string) => {
        if (itemId) {
            this.setState({selectedConsultationId: itemId})
        }

        this.setState({
            deleteModalShown: !this.state.deleteModalShown,
        });
    };

    private closeDeleteModal = () => {
        return this.setState({deleteModalShown: false});
    };

    private goToConsultationForm(id: string): void {
        window.location.href=`${process.env.REACT_APP_ONLINE_CONSULTATION_URL}/inquiry/treatment/${id}`;
    }

    private cancelConsultation(): void {
        if(this.state.selectedConsultationId) {
            return this.subscription = this.cancelOnlineConsultation(this.state.selectedConsultationId, this.props.authToken).subscribe();
        }
    }

    private openConsultation(item: {[key: string]: any}): void {
        let channel = item.agoraChannel,
            consultationId = item.id,
            secret = item.participants.find((item: { [key: string]: any }) => item.role === 'ROLE_PATIENT').accessSecret,
            role = 'ROLE_PATIENT';

        if (channel && consultationId && secret) {
            window.location.href =
                `${process.env.REACT_APP_ONLINE_CONSULTATION_URL}/dashboard/video?channel=${channel}&consultationId=${consultationId}&s=${secret}&role=${role}`;
        }
    }

    private getConsultationsList = (searchParams: typeof RestQueryParams) => {
        searchParams = searchParams.add('order[startsAt]', 'DESC');
        this.props.list(`online_consultations${searchParams.prepareQuery()}`, this.props.authToken);
    };

    private handleDateChange = (e: any) => {
        if (!e.length || isNullOrUndefined(e)) {
            return;
        }

        const startDate = new Date(e[0].getTime() - (e[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        const endDate = new Date(e[1].getTime() - (e[1].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        this.props.list(
            `online_consultations?page=1&from[after]=${startDate}&from[strictly_before]=${endDate}`,
            this.props.authToken,
        );
    };

    private cancelOnlineConsultation = (consultationId: string, authToken: string): any => {
        return cancelConsultationAPI(consultationId, authToken).pipe(
            tap(() => {
                this.alertManager.addAlert('Your online consultation was successfully cancelled');
                this.toggleDeleteModal();
            }),
            catchError((error: any) => {
                return of(this.alertManager.handleApiError(error))
            })
        );
    };

    private sortMethod(a: any, b: any): number {
        const aDate = new Date(a.startsAt),
            bDate = new Date(b.startsAt),
            aTime = aDate.getTime(),
            bTime = bDate.getTime();

        return aTime >= bTime ? -1 : 1;
    }

    private isConsultationValid(consultationDate: string): boolean {
        return new Date(consultationDate) < new Date();
    }

    private renderConsultationTime = (startsAt: Date, endsAt: Date) => {
        return (
            <React.Fragment>
                <p>{moment(startsAt).format('YYYY-MM-DD')}</p>
                <p>{moment(startsAt).format('HH:mm')} - {moment(endsAt).format('HH:mm')} </p>
            </React.Fragment>
        )
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedConsultationsListSelector(state),
        loading: consultationListLoadingSelector(state),
        error: consultationListErrorSelector(state),
        eventSource: consultationListEventSourceSelector(state),
        authToken: authTokenSelector(state)
    }),
    {
        list,
        reset,
    }
)(withRouter(Consultations)));
