import { fetch } from '../../utils/dataAccess';
import {addAlert} from "meditrip-common-web";

export function error(error: any) {
    return { type: 'CONSULTATION_DELETE_ERROR', error };
}

export function loading(loading: boolean) {
    return { type: 'CONSULTATION_DELETE_LOADING', loading };
}

export function success(deleted: any) {
    return { type: 'CONSULTATION_DELETE_SUCCESS', deleted };
}

export function del(item: any, authToken: string) {
    return (dispatch: any) => {
        dispatch(loading(true));

        return fetch(item['@id'], authToken, { method: 'DELETE' })
            .then(() => {
                dispatch(loading(false));
                dispatch(success(item));
            })
            .catch((e: any) => {
                dispatch(loading(false));
                dispatch(addAlert({message: e.message}));
                dispatch(error(e.message));
            });
    };
}
